* {
    font-family: 'Roboto';
    font-size: 16px;
}
.rbc-selected-cell {
    background-color: #2e7d3233;
}
.rbc-off-range {
    color: transparent;
}
.rbc-overlay {
    display: none;
}
